@import "../../index";

.section-notes-page {
    background-image: url("./green-stacked-peaks.svg");
    @include parallax;
    text-align: center;
    padding: 20px 1%;
    padding-bottom: 50px;

    .notes-accordion {
        margin-bottom: 20px;

        @media (max-width: $mobile_width) {
            margin: 0;
        }
    }

    .notes-container {
        padding: 20px 2%;

        .text {
            @include center;
            
            h3 {
                font-weight: 600;
                padding: 5px 0;

                @media (max-width: $tablet_width) {
                    margin-top: 20px;
                }
            }

            p {
                @include center;
                width: 100%;
                padding: 20px;
                margin: 10px 10%;
                background: #fffff58e;
                border: 2px solid #000031;
                border-radius: 20px;
                font-weight: 500;
            }
        }
    }

    .row {
        @media (max-width: $tablet_width) {
            margin: 0 -20px;
        }
    }
}

.notes-card {
    background-color: #b2d0db;
    border: 2px solid #000031;
    border-radius: 20px;
    margin: 0 20px;

    .notes-card-body {
        background-color: #ecebeb;
        color: #000031;
        font-weight: 400;
        border-radius: 0;
    }
}

.notes-accordion .notes-card:last-of-type {
    .notes-card-body {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }
}

@for $i from 1 through 6 {
    .notes-card:nth-child(#{$i}) {
        background-color: hsla(180 + $i * 10, 90%, 70%, 1);
    }
}

.notes-card-link {
    color: #000031;
    font-size: 1.05rem;
    font-weight: bold;

    &:hover {
        color: #000031;
    }
}

.notes-subject-section {
    padding: 15px 0;
}

.notes-subject-heading {
    font-weight: 600;
    padding: 5px 0;

    @media (max-width: $tablet_width) {
        margin-top: 20px;
    }
}

.notes-subject-link {
    font-size: 1.2rem;
    padding: 5px;
}

.notes-subject-link a {
    color: #046c92;
}

.notes-subject-link a:hover {
    color: #046c92c2;
}

.notes-taken-when {
    font-size: 1rem;
    text-align: left;
}

.notes-professors {
    font-size: 1rem;
    font-style: italic;
    text-align: left;
}

.notes-description {
    font-size: 1rem;
    text-align: justify;
}
