@import "../../index.scss";

.section-projects-page {
    background: url("../../images/projects.png");
    @include parallax;
    // padding: 30px 5%;

    .sub {
        width: 100%;
        height: 100%;
        padding: 30px 5%;

        // @include homePageGradient(135deg);
        background: linear-gradient(
            135deg,
            rgba(65, 194, 245, 0.7),
            rgba(88, 128, 214, 0.74),
            rgba(0, 68, 170, 0.7),
            rgba(88, 128, 214, 0.74),
            rgba(65, 194, 245, 0.7)
        );
        @include animatedGradient(20s, 3);

        h3 {
            color: $off_white;
        }
    }
}

.section-projects-page h2,
.section-projects-page p {
    text-align: center;
}

.section-projects-page h2 {
    font-weight: 600;
}

.section-projects-page .row .col-lg-6 {
    padding: 0;
    @include center;

    // set color of project folder
    @for $i from 1 to 20 {
        &:nth-child(#{$i}) {
            .project-folder {
                // background-color: hsla(30 * $i - 30, 50%, 80%, 1);
                @include fade(170deg, hsla(30 * $i - 30, 90%, 80%, 1));
            }
        }
    }
}

.project-folder {
    color: black;
    height: 300px;
    width: min(95%, 500px);
    margin: 10px 10px;
    /* make folder shape */
    clip-path: polygon(0% 0%, 70% 0%, 72% 10%, 100% 10%, 100% 100%, 0% 100%);

    margin-top: 20px;
    margin-bottom: 20px;

    h4 {
        // hide trailing text
        width: 60%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    @media (max-width: 600px) {
        height: 100px;
        border: 5px;

        margin-top: 5px;
        margin-bottom: 5px;
    }
}

@media (max-width: 480px) {
    .project-folder {
        clip-path: polygon(0% 0%, 80% 0%, 82% 10%, 100% 10%, 100% 100%, 0% 100%);
    }

    .project-folder .tools-used img {
        height: 55%;
    }
}

.project-folder .title h4 {
    position: relative;
    font-family: "Quicksand", sans-serif;
    right: -10px;
    top: 5px;
    font-size: 1.15rem;
    font-weight: 600;
}

@media (max-width: 480px) {
    .project-folder .title h4 {
        font-size: 1.05rem;
    }
}

.project-folder .project-repo {
    background-color: rgb(243, 243, 243);
    color: #000031;
    height: 60px;
    width: 30px;
    text-align: center;
    position: absolute;
}

.project-folder .project-image {
    position: relative;
    top: 3px;
    width: 100%;
}

.project-folder .project-image img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.project-folder .project-image:hover .find-me-more {
    display: block;
    background: rgba(0, 0, 0, 0.6);
}

.project-folder .find-out-more {
    width: 100%;
    height: 100%;
    display: none;
    background-color: #000000cc;
}

.project-folder .tools-used {
    background-color: #f5f5f5;
    height: 20%;
    width: 70%;
    position: absolute;
    bottom: 0px;
    right: 0px;
    /* clip-path: polygon(0% 100%, 100% 100%, 100% 0%, 15% 0); */
    border-top-left-radius: 100px;

    border-top: 3px solid #000031;

    @media (max-width: 600px) {
        height: 50%;
    }
}

.project-folder .tools-used img {
    position: relative;
    right: -17%;
    top: 4px;
    height: 55%;
    margin: 0 5px;

    @media (max-width: 800px) {
        margin: 0 3px;
    }

    @media (max-width: 480px) {
        top: 12px;
        height: 50%;
    }
}
