@import '../../index.scss';

.section-contact-me {
    background-image: url('./waves-1920x240.svg');
    /* color: #000031; */
    padding: 30px 0 30px 0;
    font-size: 1.1rem;
    font-weight: 500;
    text-align: center;
    display: grid;
    height: 240px;
    width: 100%;
    place-items: center;
}

.contact-me-details {
    padding-top: 10px;
}

#contact-me-heading {
    color: #9fb8c2;
    font-weight: 600;
    font-size: 2em;
    padding: 20px 0;
    position: relative;
    top: -10px;
}

.contact-me-link {
    color: #9fb8c2;
    font-weight: 700;
}

.contact-me-link:hover {
    color: #f5f5f5;
}

.contact-me-logo {
    padding-right: 5px;
    color: #c0c0c0;
    transform: scale(1.2);
}

.contact-me-box {
    padding: 5px 0;
}

/* contact me buttons taken from https://codepen.io/abdelrhmansaid/pen/OJRNOpQ */
.wrapper {
    display: inline-flex;
}
  
.wrapper .icon {
    position: relative;
    background-color: #79adc2;
    border-radius: 50%;
    /* border: 2px solid #000031; */
    color: #000031;
    padding: 15px;
    margin: 10px 70px;
    width: 50px;
    height: 50px;
    font-size: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.1s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

@media (max-width: 1100px) {
    .wrapper .icon {
        margin: 10px 40px;
    }
}

@media (max-width: 768px) {
    .wrapper .icon {
        margin: 10px 15px;
    }
}

@media (max-width: 500px) {
    .wrapper .icon {
        margin: 10px 2px;
    }
}

.wrapper .tooltip {
    position: absolute;
    top: 0;
    font-size: 18px;
    font-family: 'nunito', sans-serif !important;
    background-color: #ffffff;
    color: #ffffff;
    padding: 5px 8px;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background-color: #ffffff;
    bottom: -3px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon:hover .tooltip {
    top: -50px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.wrapper .facebook:hover,
.wrapper .facebook:hover .tooltip,
.wrapper .facebook:hover .tooltip::before {
    background-color: #3b5999;
    color: #ffffff;
}

.wrapper .kaggle:hover,
.wrapper .kaggle:hover .tooltip,
.wrapper .kaggle:hover .tooltip::before {
    background-color: rgb(4, 93, 128);
    color: #ffffff;
}

.wrapper .linkedin:hover,
.wrapper .linkedin:hover .tooltip,
.wrapper .linkedin:hover .tooltip::before {
    background-color: #0e76a8;
    color: #ffffff;
}

.wrapper .email:hover,
.wrapper .email:hover .tooltip,
.wrapper .email:hover .tooltip::before {
    background-color: #4e4e4e;
    color: #ffffff;
}

.wrapper .instagram:hover {
    background-color: #e33f5f;
    /* code copied from https://stackoverflow.com/questions/37751375/instagram-new-logo-css-background */
    background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
    color: #ffffff;
}

.wrapper .instagram:hover .tooltip,
.wrapper .instagram:hover .tooltip::before {
    background-color: #e33f5f;
    color: #ffffff;
}

.wrapper .github:hover,
.wrapper .github:hover .tooltip,
.wrapper .github:hover .tooltip::before {
    background-color: #333333;
    color: #ffffff;
}

.wrapper .youtube:hover,
.wrapper .youtube:hover .tooltip,
.wrapper .youtube:hover .tooltip::before {
    background-color: #de463b;
    color: #ffffff;
}
