@use "sass:math";

@import "../../index.scss";

.section-top-welcome {
	@include homePageGradient(135deg);
	margin-top: 50px;
	height: 90vh;
	border-bottom: 5px black solid;
	@include center;

	.description {
		width: 80%;

		h1 {
			margin-bottom: 25px;
		}
		
		h1, h5 {
			// text-align: center;
		}

		@media (max-width: $tablet_width) {
			width: 90%;
		}
	}
}

