// use sass math
@use "sass:math";
@use "sass:list";

// media breakpoints
$small_mobile_width: 360px;
$mobile_width: 480px;
$tablet_width: 768px;
$medium_desktop_width: 990px;
$desktop_width: 1100px;
$large_desktop_width: 1280px;

// color scheme
$off_white: #f5f5f5;
$dark_blue: #000031;

html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

body {
    font-family: "nunito", sans-serif;
    font-weight: 300;
    font-size: 1.1rem;
}

h1 {
    font-family: "quicksand", sans-serif;
    font-weight: 700;
    font-size: 3.5em;

    @media (max-width: $tablet_width) {
        font-size: 3em;
    }
}

h2 {
    font-family: "quicksand", sans-serif;
    font-weight: 500;
    font-size: 3em;
}

h3 {
    font-size: 2.8em;
    // color: white;
    text-align: center;
    font-weight: 600;
    font-family: "Quicksand", sans-serif;

    padding: 20px;
}

h4 {
    font-size: 2.4em;
    font-weight: 600;
    font-family: "Quicksand", sans-serif;

    @media (max-width: $tablet_width) {
        font-size: 2em;
    }
}

h5 {
    font-size: 1.8em;
    font-weight: 500;
    font-family: "Quicksand", sans-serif;

    @media (max-width: $tablet_width) {
        font-size: 1.4em;
    }

    @media (max-width: $mobile_width) {
        font-size: 1.2em;
    }
}

h6 {
    font-size: 1.5em;
    font-weight: 500;
    font-family: "Nunito Sans", sans-serif;

    @media (max-width: $tablet_width) {
        font-size: 1.2em;
    }
}

p {
    font-size: 1.1em;
    font-weight: 300;

    @media (max-width: $tablet_width) {
        font-size: 1em;
    }
}

// lightbulb bullet points
ul {
    list-style-image: url("./images/lightbulb.png");
}

li {
    // font-family: "nunito", sans-serif;
    // font-weight: 400;
    margin-left: -10px;
    // margin-bottom: 10px;
}

#root {
    overflow-x: hidden;
    scroll-behavior: smooth;
}

// helper code to 'center' elements
@mixin center {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

// nice bg
@mixin nicebg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

// parallax scrolling effect
@mixin parallax {
    background-attachment: fixed;
    @include nicebg;
}

// gradient for fade
@mixin fade($deg, $color) {
    // background-color: $color;
    background: linear-gradient($deg, $color 0%, white 100%);
    // background: linear-gradient(30deg, #00cccc 0%, #185fe2 50%, #1100ac 100%);
}

$homepage_colors: (
    // $deg,
    rgba(245, 245, 245, 0.7) 0%,
    rgba(118, 235, 207, 0.7) 25%,
    rgba(113, 198, 238, 0.7) 50%,
    rgba(104, 148, 230, 0.7) 75%,
    rgba(125, 94, 238, 0.7) 100%
);

$homepage_colors_light: (
    // $deg,
    rgb(202, 254, 243) 0%,
    rgb(245, 245, 245) 50%,
    rgb(217, 230, 255) 100%,
);

// home page gradient
@mixin homePageGradient($deg) {

    // background-image: linear-gradient($homepage_colors);
    background-image: radial-gradient(
        farthest-corner at 40px 40px,
        $homepage_colors
    ), url("images/white-wave.png");
    background-size: cover;
}

// general light to dark gradient, given hue and degree
@mixin lightToDarkGradient($deg, $hue, $transp) {
    background: linear-gradient($deg, hsla($hue, 90%, 50%, $transp) 0%, hsla($hue, 70%, 60%, $transp) 100%);
}

// dark border options
$dark_border: 5px solid #000031;
@mixin dark_border {
    border: 5px solid #000031;
    border-radius: 10px;
}

// cyan-to-dark-blue gradient
@mixin cyanToDarkBlueGradient($deg, $mid) {
    background: linear-gradient($deg, #00cccc 0%, #185fe2 $mid, #1100ac 100%);
}

// dark-cyan-to-dark-blue gradient
@mixin darkCyanToDarkBlueGradient($deg, $mid) {
    background: linear-gradient($deg, #288181 0%, #0e3988 $mid, #1000a1 100%);
}

// animated gradient
@mixin animatedGradient($duration, $noColours) {
    background-size: 200% * $noColours 200% * $noColours;
    animation: gradientAnimate $duration ease alternate infinite;

    @keyframes gradientAnimate {
        0% {
            background-position: 0% 0%;
        }
        100% {
            background-position: 100% 100%;
        }
    }
}

// cyan-to-dark-blue animated gradient
@mixin cyanToDarkBlueAnimatedGradient($duration) {
    background: linear-gradient(
        135deg,
        #00eeee,
        #2e68d4,
        #1907b9,
        #00eeee,
        #2e68d4,
        #1907b9,
        #00eeee,
        #2e68d4,
        #1907b9
    );
    @include animatedGradient($duration, 3);
}
