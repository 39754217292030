@import '../../index.scss';

.section-not-found {
    margin-top: 40px;
    height: 85vh;

    .gradient {
        height: 100%;
        width: 100%;

        @include homePageGradient(135deg);
        @include center;
        
        .desc {
            @include center;
            width: 60%;
            
            h1, h3 {
                text-align: center;
            }

            @media (max-width: $tablet_width) {
                width: 90%;
            }
        }
    }
}