@import "../../index";

.project-content {
    .project-cover {
        height: 70vh;
        @include parallax;

        .sub {
            width: 100%;
            height: 100%;
            @include center;
            background: linear-gradient(
                135deg,
                rgba(245, 245, 245, 0.7) 0%,
                rgba(167, 233, 233, 0.7) 34%,
                rgba(69, 152, 207, 0.7) 66%,
                rgba(6, 59, 139, 0.7) 100%
            );

            .title {
                @include center;
                width: 50%;
                padding: 20px;
                background: #f5f5f5ee;
                border: 4px solid #000031;

                h1 {
                    font-weight: 300;
                    font-size: 2em;
                    width: 100%;
                    text-align: center;
                }

                h4 {
                    font-size: 1.5em;

                    i {
                        position: relative;
                        color: #000031;
                        padding: 0 10px;
                    }

                    a {
                        color: rgb(4, 138, 228);
                    }

                    a:hover {
                        color: rgba(4, 138, 228, 0.7);
                    }
                }

                @media (max-width: $tablet_width) {
                    width: 80%;
                    h1 {
                        font-size: 2em;
                    }
                }
            }
        }
    }

    .project-description {
        @include center;
        margin-top: 40px;
        margin-bottom: 40px;

        .description {
            width: 60%;
            text-align: left;

            h1 {
                font-size: 2.5em;
            }

            h2 {
                font-size: 2.2em;
            }

            @media (max-width: $mobile_width) {
                width: 90%;
            }

        }

    }
}
