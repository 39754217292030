@import "../../index.scss";

.section-about-me {
    padding-top: 30px;
    padding-bottom: 30px;

    background-image: radial-gradient(#b9b9f0cc 0%, #000031cc 100%), 
    url("./geometric-1920x1080.svg");
    @include parallax;
    text-align: center;
    border-bottom: 5px solid black;
    display: flex;

    .wrapper {
        margin-top: 20px;
        // gradient 'border'
        // background: linear-gradient(135deg, #00eeee 0%, #2e68d4 34%, #1907b9 66%, #4d0bc7 100%);        @include cyanToDarkBlueAnimatedGradient(20s);
        border-radius: 20px;
        // min-height: 90vh;

        .personal-facts {
            height: 100%;
            width: 100%;

            // min-height: 90vh;
            margin: 5px;
            padding: 20px 30px;

            background-color: #f5f5f5ee;
            text-align: left;
            border-radius: 20px;
        }
    }
}

#container-about-me {
    padding: 0;
    margin: auto;
}

#about-me-title {
    background-color: rgba(248, 248, 248, 0.6);
    border-radius: 10px;
    text-align: center;
}

.personal-photo {
    // background: white;
    // padding: 20px 0;
    border-radius: 20px;
    height: 90vh;
    width: 100%;
    overflow: hidden;
    @include cyanToDarkBlueAnimatedGradient(30s);

    #personal-photo {
        // make picture fit div
        width: 100%;
        padding: 5px;
        height: 100%;
        object-fit: cover;
        border-radius: inherit;
    }
    // }
}

.personal-desc-section {
    margin-top: 2px;
    padding: 30px 20px 20px 20px;
    background-color: #f5f5f5cc;
    text-align: justify;
}

.personal-facts-col {
    height: 100%;
}

.personal-facts {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 10px 0;

    .first, .second {
        width: 50%;
        height: 100%;
        padding: 0 10px;

        @media (max-width: $tablet_width) {
            width: 90%;
        }

        .pro-title {
            font-weight: 900;
        }
    }

    a, span {
        font-weight: 400;
        font-size: 1.05rem;
        color: rgb(25, 25, 25);
    }

    .personal-facts-head {
        h5 {
            background: linear-gradient(90deg, rgb(4, 83, 201) 0%, rgb(42, 59, 211) 50%, rgb(32, 4, 156) 100%);
            background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
            font-weight: 600;
        }

        display: flex;
        align-items: center;
    }

    .personal-facts-icon {
        @include cyanToDarkBlueGradient(45deg, 50%);
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        color: transparent;
        text-align: center;

        position: relative;
        margin-left: -5px;
        margin-right: 10px;
        width: 40px;
        font-size: 1em !important;

        @media (max-width: $tablet_width) {
            margin-right: 4px;
        }

        @media (max-width: $mobile_width) {
            width: 30px;
        }
    }

    .personal-facts-body {
        font-weight: 400;
        font-size: 1.05rem;
        margin: 2px 0 15px 0;
        min-height: 100%;
        // font-family: 'Comic Neue', sans-serif;
    }
}

.start-animation {
    // number of about me headers
    $n: 5;
    // width of icon
    $cm_width: 40px;

    // time it takes for logo to appear (in seconds)
    $logo_appear_time: 0.2;
    // time it takes for logo link to appear (in seconds)
    $logo_link_appear_time: 1;

    .personal-facts-icon {
        animation: logo-appear-and-move 2s forwards ease;

        @for $i from 1 to $n {
            &:nth-of-type(#{$i}) {
                animation-delay: #{$logo_appear_time * $i}s;
            }

            .logo-link:nth-of-type(#{$i}) {
                animation-delay: #{$logo_link_appear_time * $i}s;
            }
        }

        @keyframes logo-appear-and-move {
            0% {
                opacity: 0;
                transform: translateX($cm_width);
            }
            #{$logo_appear_time / ($logo_appear_time * $logo_link_appear_time) * 100%} {
                opacity: 1;
                transform: translateX($cm_width);
            }
            100% {
                opacity: 1;
                transform: translateX(0);
            }
        }
    }
}

.personal-description {
    font-size: 1.1rem;
    font-weight: 400;
}
